<template>
    <v-card color="panel" class="h-100 mb-0">
        <v-toolbar color="toolbar" dense class="remove-padding" :extended="$vuetify.breakpoint.xsOnly">
            <v-toolbar-title class="mx-3">Index of {{modelName}}</v-toolbar-title>
            <v-text-field v-if="$vuetify.breakpoint.smAndUp" v-model="table.search" label="Search" hide-details single-line outline append-icon="mdi-database-search" @click:append="load" @change="load" cb="load" @keyup="cbIfEnter" :loading="loading" :disabled="loading || !$io.connected" clearable></v-text-field>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon v-if="addTo" :to="addTo">
                    <v-icon>mdi-shape-square-rounded-plus</v-icon>
                </v-btn>
                <v-btn icon @click="load" :loading="loading" :disabled="!$io.connected">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar-items>
            <template v-slot:extension v-if="$vuetify.breakpoint.xsOnly">
                <v-text-field v-if="$vuetify.breakpoint.xsOnly" v-model="table.search" label="Search" hide-details single-line outline append-icon="mdi-database-search" @click:append="load" @change="load" cb="load" @keyup="cbIfEnter" :loading="loading" :disabled="loading || !$io.connected" clearable></v-text-field>
            </template>
        </v-toolbar>
        <v-divider color="white"></v-divider>
        <v-data-table
            :headers="table.headers"
            :items="table.items"
            :options.sync="table.options"
            :server-items-length="table['server-items-length']"
            :loading="loading"
            class="panel"
            @update:options="load"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn v-for="(action) in item.actions" :key="action" v-bind="actionButtons.binds[action]" v-on="actionButtons.ons[action]" @click="actionButtons.ons[action].click(item)">
                    <v-icon v-text="actionButtons.icons[action]"></v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
        </v-overlay>
        <v-overlay absolute :value="error.show">
            <v-card color="panel" tile>
                <v-toolbar color="toolbar">
                    <v-toolbar-title class="error--text">Error</v-toolbar-title>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-3">
                    <p class="mb-0" v-text="error.text"></p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="load">
                        Retry
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </v-card>
</template>

<script>
import shortid from 'shortid';
import merge from 'lodash.merge';

export default {
    props: {
        model: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            default: 'Model',
        },
        addTo: {
            type: Object,
        },
        viewTo: {
            type: Object,
        },
    },
    data: () => ({
        loading: true,
        table: {
            headers: [],
            "server-items-length": 0,
            items: [],
            search: '',
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: true,
                mustSort: false
            }
        },
        requests: {
            load: null,
        },
        error: {
            show: false,
            text: '',
        }
    }),
    computed: {
        actionButtons() {
            return {
                icons: {
                    edit: 'mdi-lead-pencil',
                    enable: 'mdi-lightbulb-on',
                    disable: 'mdi-lightbulb-off',
                },
                binds: {
                    edit: {
                        icon: true,
                        color: 'warning',
                    },
                    enable: {
                        icon: true,
                        color: 'success',
                        disabled: !this.$io.connected,
                        loading: this.loading,
                    },
                    disable: {
                        icon: true,
                        color: 'error',
                        disabled: !this.$io.connected,
                        loading: this.loading,
                    },
                },  
                ons: {  
                    edit: {
                        click: (item) => {
                            if (item.target) {
                                return;
                            }
                            const route = merge({}, this.viewTo, {
                                params: item,
                            })
                            this.$router.push(route, () => {}, () => {});
                        }
                    },
                    enable: {
                        click: async (item) => {
                            if (item.target) {
                                return;
                            }
                            this.error.show = false;
                            this.loading = true;
                            const id = shortid.generate();
                            this.requests.enable = id;
                            const payload = {
                                id: item.id,
                                enabled: true,
                            }
                            try {
                                await this.$io.request(this.model, 'setEnabled', [payload], 60000);
                                if (this.requests.enable == id) {
                                    await this.load();
                                }
                            }
                            catch (error) {
                                if (this.requests.enable == id) {
                                    this.$PNotify.error({
                                        title: 'Error',
                                        text: error.toString(),
                                    })
                                    await this.load();
                                }
                            }
                        }
                    },
                    disable: {
                        click: async (item) => {
                            if (item.target) {
                                return;
                            }
                            this.error.show = false;
                            this.loading = true;
                            const id = shortid.generate();
                            this.requests.disable = id;
                            const payload = {
                                id: item.id,
                                enabled: false,
                            }
                            try {
                                await this.$io.request(this.model, 'setEnabled', [payload], 60000);
                                if (this.requests.disable == id) {
                                    await this.load();
                                }
                            }
                            catch (error) {
                                if (this.requests.disable == id) {
                                    this.$PNotify.error({
                                        title: 'Error',
                                        text: error.toString(),
                                    })
                                    await this.load();
                                }
                            }
                        }
                    },
                }
            }
        }
    },
    methods: {
        async load() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loading = true;
            const id = shortid.generate();
            this.requests.load = id;
            const payload = merge({}, this.table.options, {
                search: this.table.search,
                rid: id,
            })
            try {
                const res = await this.$io.request(this.model, 'listResource', [payload], 60000);
                if (this.requests.load == res.rid) {
                    while (this.table.headers.length > 0) {
                        this.table.headers.splice(0, 1);
                    }
                    for (let i = 0; i < res.headers.length; i++) {
                        const header = res.headers[i];
                        this.table.headers.push(header);
                    }
                    while (this.table.items.length > 0) {
                        this.table.items.splice(0, 1);
                    }
                    for (let i = 0; i < res.items.length; i++) {
                        const item = res.items[i];
                        this.table.items.push(item);
                    }
                    this.table['server-items-length'] = res['server-items-length'];
                    this.loading = false;
                }
            }
            catch (error) {
                if (this.requests.load == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loading = false;
                }
            }
        }
    },
    mounted() {
        this.$io.$on('connected', this.load);
    },
    beforeDestroy() {
        this.$io.$off('connected', this.load);
    },
}
</script>