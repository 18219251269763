<template>
    <v-sheet style="position: relative;" color="background" class="fill-height pb-10">
        <adminSidebar></adminSidebar>
         <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <v-card color="panel" class="h-100 mb-0">
                        <v-toolbar color="toolbar" dense class="remove-padding">
                            <v-toolbar-title class="mx-3" v-text="title"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn icon @click="saveModel" :loading="loading" :disabled="!$io.connected">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                                <v-btn icon v-if="$route.meta.edit.addTo && !$route.meta.edit.add" :to="$route.meta.edit.addTo">
                                    <v-icon>mdi-shape-square-rounded-plus</v-icon>
                                </v-btn>
                                <v-btn icon @click="load" :loading="loading" :disabled="!$io.connected">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-btn fab small fixed bottom right style="bottom: 30px;" color="secondary" @click="recalculate">
                            <v-icon>mdi-calculator</v-icon>
                        </v-btn>
                        <v-divider color="white"></v-divider>
                        <validation-observer slim ref="observer">
                            <v-simple-table fixed-header class="panel" v-if="firstLoaded.options" ref="table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="panel">&nbsp;</th>
                                            <th class="panel">Spend</th>
                                            <th class="panel">CPA</th>
                                            <th class="panel">FTDs</th>
                                        </tr>
                                    </thead>
                                    <tbody :key="['revision', u].join('-')">
                                        <row
                                            text="Summary"
                                            :spend.sync="form.spend"
                                            :cpa.sync="form.cpa"
                                            :ftds.sync="form.ftds"
                                            @blurred="onBlurred"
                                            :children="form.breakdown"
                                            :loading="!loaded.model"
                                            :key="`summary${uu}`"
                                            ref="summary"
                                        >
                                            <template v-slot:firstCell>
                                                <td class="pr-0">
                                                    <div class="d-flex w-100">
                                                        <validation-provider v-slot="{ errors }" name="Month" rules="required" class="flex-grow-1">
                                                            <v-select dense v-model.number="form.month" :items="months" label="Month" :loading="!loaded.model" :disabled="!loaded.model || !$route.meta.edit.add" :error-messages="errors" :hide-details="0 == errors.length"></v-select>
                                                        </validation-provider>
                                                        <validation-provider v-slot="{ errors }" name="Year" rules="required" class="flex-grow-1 text-right">
                                                            <v-text-field class="input-text-right" dense type="number" v-model.number="form.year" label="Year" :loading="!loaded.model" :disabled="!loaded.model || !$route.meta.edit.add" :error-messages="errors" :hide-details="0 == errors.length"></v-text-field>
                                                        </validation-provider>
                                                    </div>
                                                </td>
                                            </template>
                                        </row>
                                        <row
                                            text="Warm Traffic"
                                            :spend.sync="form.breakdown.warm.spend"
                                            :cpa.sync="form.breakdown.warm.cpa"
                                            :ftds.sync="form.breakdown.warm.ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form"
                                            :children="form.breakdown.warm.breakdown"
                                            :loading="!loaded.model"
                                            :key="`warm${uu}`"
                                            :parentRef="_refs.summary"
                                            ref="warmL0"
                                        ></row>
                                        <row
                                            offset="1"
                                            text="SMS"
                                            :spend.sync="form.breakdown.warm.breakdown.sms.spend"
                                            :cpa.sync="form.breakdown.warm.breakdown.sms.cpa"
                                            :ftds.sync="form.breakdown.warm.breakdown.sms.ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form.breakdown.warm.breakdown"
                                            :children="form.breakdown.warm.breakdown.sms.breakdown"
                                            :loading="!loaded.model"
                                            :parentRef="_refs.warmL0"
                                            :key="`sms${uu}`"
                                            ref="warmSMS"
                                        ></row>
                                    </tbody>
                                    <!-- SMS Publisher Children -->
                                    <tbody v-if="options && options.publishers">
                                        <row
                                            v-for="(item, i) in options.publishers.sms" :key="['publisher', 'sms', i, uu].join('-')"
                                            offset="2"
                                            :text="item.text"
                                            :spend.sync="form.breakdown.warm.breakdown.sms.breakdown[item.value.toString()].spend"
                                            :cpa.sync="form.breakdown.warm.breakdown.sms.breakdown[item.value.toString()].cpa"
                                            :ftds.sync="form.breakdown.warm.breakdown.sms.breakdown[item.value.toString()].ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form.breakdown.warm.breakdown.sms.breakdown"
                                            :loading="!loaded.model"
                                            :parentRef="_refs.warmSMS"
                                        ></row>
                                    </tbody>
                                    <tbody>
                                        <row
                                            offset="1"
                                            text="Media"
                                            :spend.sync="form.breakdown.warm.breakdown.media.spend"
                                            :cpa.sync="form.breakdown.warm.breakdown.media.cpa"
                                            :ftds.sync="form.breakdown.warm.breakdown.media.ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form.breakdown.warm.breakdown"
                                            :children="form.breakdown.warm.breakdown.media.breakdown"
                                            :loading="!loaded.model"
                                            :parentRef="_refs.warmL0"
                                            :key="`media${uu}`"
                                            ref="warmMedia"
                                        ></row>
                                    </tbody>
                                    <!-- Media Publisher Children -->
                                    <tbody v-if="options && options.publishers">
                                        <row
                                            v-for="(item, i) in options.publishers.media" :key="['publisher', 'media', i, uu].join('-')"
                                            offset="2"
                                            :text="item.text"
                                            :spend.sync="form.breakdown.warm.breakdown.media.breakdown[item.value.toString()].spend"
                                            :cpa.sync="form.breakdown.warm.breakdown.media.breakdown[item.value.toString()].cpa"
                                            :ftds.sync="form.breakdown.warm.breakdown.media.breakdown[item.value.toString()].ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form.breakdown.warm.breakdown.media.breakdown"
                                            :loading="!loaded.model"
                                            :parentRef="_refs.warmMedia"
                                        ></row>
                                    </tbody>
                                    <tbody>
                                        <!-- Start Cold Traffic -->
                                        <row
                                            text="Cold Traffic"
                                            :spend.sync="form.breakdown.cold.spend"
                                            :cpa.sync="form.breakdown.cold.cpa"
                                            :ftds.sync="form.breakdown.cold.ftds"
                                            @blurred="onBlurred"
                                            :parent.sync="form"
                                            :children="form.breakdown.cold.breakdown"
                                            :loading="!loaded.model"
                                            :key="`cold${uu}`"
                                            ref="coldL0"
                                        ></row>
                                    </tbody>
                                    <template v-if="options.crms">
                                        <tbody v-for="(crm, i) in options.crms" :key="['crm', i].join('-')">
                                            <row
                                                offset="1"
                                                :text="crm.text"
                                                :spend.sync="form.breakdown.cold.breakdown[crm.value.toString()].spend"
                                                :cpa.sync="form.breakdown.cold.breakdown[crm.value.toString()].cpa"
                                                :ftds.sync="form.breakdown.cold.breakdown[crm.value.toString()].ftds"
                                                @blurred="onBlurred"
                                                :parent.sync="form.breakdown.cold.breakdown"
                                                :children="form.breakdown.cold.breakdown[crm.value.toString()]"
                                                :loading="!loaded.model"
                                                :ref="['coldL1', 'crm', crm.value].join('-')"
                                                :parentRef="_refs.coldL0"
                                                :key="`coldL1${uu}`"
                                            ></row>
                                            <row
                                                offset="2"
                                                text="Internal"
                                                :spend.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.spend"
                                                :cpa.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.cpa"
                                                :ftds.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.ftds"
                                                @blurred="onBlurred"
                                                :parent.sync="form.breakdown.cold.breakdown[crm.value.toString()]"
                                                :children="form.breakdown.cold.breakdown[crm.value.toString()].internal.breakdown"
                                                :loading="!loaded.model"
                                                :ref="['coldL2', 'crm', crm.value, 'Internal'].join('-')"
                                                :parentRef="_refs[`coldL1-crm-${crm.value}`]"
                                                :key="['crm', i, 'internal', uu].join('-')"
                                                internal
                                            ></row>
                                            <!-- Loop through crm internal departments -->
                                            <row
                                                v-for="(dep, di) in crm.internal" :key="['crm', i, 'internal', di, uu].join('-')"
                                                offset="3"
                                                :text="dep.text"
                                                :spend.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.breakdown[dep.value.toString()].spend"
                                                :cpa.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.breakdown[dep.value.toString()].cpa"
                                                :ftds.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal.breakdown[dep.value.toString()].ftds"
                                                @blurred="onBlurred"
                                                :parent.sync="form.breakdown.cold.breakdown[crm.value.toString()].internal"
                                                :loading="!loaded.model"
                                                internal
                                                :parentRef="_refs[`coldL2-crm-${crm.value}-Internal`]"
                                            ></row>
                                            <row
                                                offset="2"
                                                text="External"
                                                :spend.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.spend"
                                                :cpa.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.cpa"
                                                :ftds.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.ftds"
                                                @blurred="onBlurred"
                                                :parent.sync="form.breakdown.cold.breakdown[crm.value.toString()]"
                                                :children="form.breakdown.cold.breakdown[crm.value.toString()].external.breakdown"
                                                :loading="!loaded.model"
                                                :ref="['coldL2', 'crm', crm.value, 'External'].join('-')"
                                                :parentRef="_refs[`coldL1-crm-${crm.value}`]"
                                                :key="['crm', i, 'external', uu].join('-')"
                                            ></row>
                                            <!-- Loop through crm external departments -->
                                            <row
                                                v-for="(dep, di) in crm.external" :key="['crm', i, 'external', di, uu].join('-')"
                                                offset="3"
                                                :text="dep.text"
                                                :spend.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.breakdown[dep.value.toString()].spend"
                                                :cpa.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.breakdown[dep.value.toString()].cpa"
                                                :ftds.sync="form.breakdown.cold.breakdown[crm.value.toString()].external.breakdown[dep.value.toString()].ftds"
                                                @blurred="onBlurred"
                                                :parent.sync="form.breakdown.cold.breakdown[crm.value.toString()].external"
                                                :loading="!loaded.model"
                                                :parentRef="_refs[`coldL2-crm-${crm.value}-External`]"
                                            ></row>
                                        </tbody>
                                    </template>
                                </template>
                            </v-simple-table>
                        </validation-observer>
                        <v-overlay absolute :value="loading">
                            <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
                        </v-overlay>
                        <v-overlay absolute :value="error.show">
                            <v-card color="panel" tile>
                                <v-toolbar color="toolbar">
                                    <v-toolbar-title class="error--text">Error</v-toolbar-title>
                                </v-toolbar>
                                <v-divider color="white"></v-divider>
                                <v-card-text class="pa-3">
                                    <p class="mb-0" v-text="error.text"></p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="load" :loading="loading">
                                        Retry
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-overlay>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../../components/admin/sidebar';
import row from '../../../components/targets/row';
import shortid from 'shortid';
import merge from 'lodash.merge';
import dot from 'dot-object';
import moment from 'moment';
export default {
    components: {
        adminSidebar,
        row,
    },
    data: () => ({
        loaded: {
            options: false,
            model: false,
            first: false,
        },
        firstLoaded: {
            options: false,
            model: false,
        },
        form: {
            month: parseInt(moment().format('M')) - 1,
            year: parseInt(moment().format('YYYY')),
            spend: 0,
            cpa: 0,
            ftds: 0,
            breakdown: {
                warm: {
                    spend: 0,
                    cpa: 0,
                    ftds: 0,
                    breakdown: {
                        sms: {
                            spend: 0,
                            cpa: 0,
                            ftds: 0,
                            breakdown: {},
                        },
                        media: {
                            spend: 0,
                            cpa: 0,
                            ftds: 0,
                            breakdown: {},
                        },
                    },
                },
                cold: {
                    spend: 0,
                    cpa: 0,
                    ftds: 0,
                    breakdown: {},
                },
            },
        },
        instance: {},
        requests: {
            loadFields: null,
        },
        error: {
            show: false,
            text: '',
        },
        timeouts: {},
        options: {},
        u: 0,
        uu: 0,
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        firstLoading() {
            let ret = false;
            for (let key in this.firstLoaded) {
                if (false == this.firstLoaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        title() {
            if (this.$route.meta.edit.add) {
                return `Add ${this.$route.meta.edit.modelName}`;
            }
            else if (this.loading) {
                return `Loading ${this.$route.meta.edit.modelName}`;
            }
            else if (this.$route.meta.edit.options && this.$route.meta.edit.options.displayKey) {
                const display = dot.pick(this.$route.meta.edit.options.displayKey, this.instance, false);
                if (display) {
                    return `Manage ${display}`;
                }
            }
            return `Manage ${this.$route.meta.edit.modelName}`;
        },
        internalCardColor() {
            return (this.$vuetify.theme.dark) ? 'panel lighten-1' : 'panel darken-1'
        },
        internalCardToolbarColor() {
            return (this.$vuetify.theme.dark) ? 'toolbar lighten-1' : 'toolbar darken-1'
        },
        months() {
            const ret = [];
            let i = 0;
            while (i < 12) {
                const m = moment().month(i);
                ret.push({
                    value: i,
                    text: m.format('MMMM'),
                })
                i ++;
            }
            return ret;
        },
        _refs() {
            this.u;
            return this.$refs;
        }
    },
    methods: {
        async load() {
            const promises = [
                this.loadOptions(),
                this.loadModel(),
            ];
            if (promises.length > 0) {
                await Promise.all(promises);
            }
        },
        async loadOptions() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loaded.options = false;
            const id = shortid.generate();
            this.requests.loadOptions = id;
            try {
                const res = await this.$io.request(this.$route.meta.edit.model, 'getTargetOptions', [], 60000);
                if (this.requests.loadOptions == id) {
                    this.options = res;
                    this.$emit('options:updated')
                    this.loaded.options = true;
                    this.firstLoaded.options = true;
                }
            }
            catch (error) {
                if (this.requests.loadOptions == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loaded.options = true;
                    this.firstLoaded.options = true;
                }
            }
        },
        async loadModel() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            if (this.$route.meta && this.$route.meta.edit.add) {
                this.loaded.model = true;
                this.firstLoaded.model = true;
                return;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.loadModel = id;
            try {
                const res = await this.$io.request(this.$route.meta.edit.model, 'viewResource', [this.$route.params.id], 60000);
                if (this.requests.loadModel == id) {
                    this.instance = res;
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                    this.$emit('model:updated')
                }
            }
            catch (error) {
                if (this.requests.loadModel == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                }
            }
        },
        async saveModel() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.observer.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.saveModel = id;
            try {
                const uid = await this.$io.request(this.$route.meta.edit.model, 'saveTargetResource', [this.$route.params.id, this.form], 60000);
                if (this.requests.saveModel == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Target Successfully',
                    })
                    if (this.$route.meta.edit.add) {
                        const route = merge({}, this.$route.meta.edit.viewTo, {
                            params: {
                                id: uid,
                            },
                        })
                        this.$router.push(route, () => {}, () => {});
                    }
                    else {
                        this.loaded.model = true;
                    }
                }
            }
            catch (error) {
                if (this.requests.saveModel == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadModel();
                }
            }
        },
        updateForm() {
            const {publishers, crms} = this.options;
            if (publishers) {
                const {sms, media} = publishers;
                if (sms) {
                    for (let i = 0; i < sms.length; i++) {
                        const pub = sms[i];
                        // this.form.breakdown.warm.breakdown.sms.breakdown
                        if ('undefined' == typeof this.form.breakdown.warm.breakdown.sms.breakdown[`${pub.value}`]) {
                            this.form.breakdown.warm.breakdown.sms.breakdown[`${pub.value}`] = {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                            }
                        }
                    }
                }
                if (media) {
                    for (let i = 0; i < media.length; i++) {
                        const pub = media[i];
                        // this.form.breakdown.warm.breakdown.media.breakdown
                        if ('undefined' == typeof this.form.breakdown.warm.breakdown.media.breakdown[`${pub.value}`]) {
                            this.form.breakdown.warm.breakdown.media.breakdown[`${pub.value}`] = {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                            }
                        }
                    }
                }
            }
            if (crms) {
                for (let i = 0; i < crms.length; i++) {
                    const crm = crms[i];
                    const {internal, external} = crm;
                    // this.form.breakdown.cold.breakdown
                    if ('undefined' == typeof this.form.breakdown.cold.breakdown[`${crm.value}`]) {
                        this.form.breakdown.cold.breakdown[`${crm.value}`] = {
                            spend: 0,
                            cpa: 0,
                            ftds: 0,
                            internal: {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                                breakdown: {},
                            },
                            external: {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                                breakdown: {},
                            },
                        }
                    }
                    for (let ii = 0; ii < internal.length; ii++) {
                        const dep = internal[ii];
                        // this.form.breakdown.cold.breakdown[`${crm.value}`].internal.breakdown
                        if ('undefined' == typeof this.form.breakdown.cold.breakdown[`${crm.value}`].internal.breakdown[`${dep.value}`]) {
                            this.form.breakdown.cold.breakdown[`${crm.value}`].internal.breakdown[`${dep.value}`] = {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                            }
                        }
                    }
                    for (let ii = 0; ii < external.length; ii++) {
                        const dep = external[ii];
                        // this.form.breakdown.cold.breakdown[`${crm.value}`].external.breakdown
                        if ('undefined' == typeof this.form.breakdown.cold.breakdown[`${crm.value}`].external.breakdown[`${dep.value}`]) {
                            this.form.breakdown.cold.breakdown[`${crm.value}`].external.breakdown[`${dep.value}`] = {
                                spend: 0,
                                cpa: 0,
                                ftds: 0,
                            }
                        }
                    }
                }
            }
            if (!this.$route.meta.add && Object.keys(this.instance).length > 0) {
                merge(this.form.breakdown, this.instance.breakdown);
                this.form.month = this.instance.month;
                this.form.year = this.instance.year;
                this.form.spend = this.instance.spend;
                this.form.cpa = this.instance.cpa;
                this.form.ftds = this.instance.ftds;
            }
            this.$nextTick(() => {
                this.u ++;
            })
        },
        headerCellClass(additional) {
            const ignore = [
                'v-data-table',
                'v-data-table--dense',
                'v-data-table--fixed-header',
                'theme--dark',
                'theme--light',
            ];
            const ret = new Set();
            if (this.$el && this.$el.classList) {
                [...this.$el.classList].filter((v) => {
                    return (ignore.indexOf(v) == -1);
                }).map((v) => {
                    ret.add(v);
                })
            }
            if (Array.isArray(additional)) {
                for (let i = 0; i < additional.length; i++) {
                    const add = additional[i];
                    ret.add(add);
                }
            }
            else if ('string' == typeof additional) {
                const parts = additional.split(' ');
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    ret.add(part);
                }
            }
            return [...ret].filter((v) => {
                return ('string' == typeof v);
            }).map((v) => {
                return v.trim();
            }).join(' ');
        },
        recalculate() {
            if (this.$refs.table) {
                this.$refs.table.$children.map((v) => {
                    if ('function' == typeof v.updateParent) {
                        if ('undefined' == typeof v.children) {
                            v.updateParent();
                        }
                    }
                })
            }
            this.$nextTick(() => {
                if (this.$refs.summary) {
                    this.$refs.summary.$trigger('updated:children');
                    this.$refs.summary.minimums = this.$refs.summary.getMinimums();
                }
            })
        },
        onBlurred() {
            this.$nextTick(() => {
                this.recalculate();
            })
        }
    },
    mounted() {
        window.__tf = this;
        this.timeouts.initial = setTimeout(() => {
            this.load();
        }, 250);
        this.$io.$on('connected', this.load);
        this.$on('options:updated', this.updateForm)
        this.$on('model:updated', this.updateForm)
        this.$nextTick(() => {
            this.u ++;
        })
        this.$watch('loading', () => {
            this.$nextTick(() => {
                this.u ++;
            })
        })
    },
    beforeDestroy() {
        for (let i in this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
        this.$io.$off('connected', this.load);
    },
    watch: {
        firstLoading() {
            console.debug('firstLoading', this.firstLoading);
            clearTimeout(this.timeouts.firstLoad);
            this.timeouts.firstLoad = setTimeout(() => {
                this.loaded.first = (false == this.firstLoading);
            }, 500)
        },
        "loaded.first": function() {
            this.$nextTick(() => {
                this.u ++;
            })
        }
    }
}
</script>

<style lang="scss">
    .input-text-currency-icon {
        position: relative;
        top: 6px;
    }
</style>