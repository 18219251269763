<template>
    <tr :class="rowClass">
        <slot name="firstCell">
            <td>
                <label :class="labelClass">
                    <v-icon v-if="offset > 0" small class="mr-3">mdi-arrow-bottom-right</v-icon>
                    <span v-text="text"></span>
                    <!-- <code class="mx-3" v-text="JSON.stringify(touchOrder)"></code> -->
                </label>
            </td>
        </slot>
        <td class="px-0">
            <validation-provider v-slot="{ errors }" name="Spend" :rules="rules.spend" class="flex-grow-1 text-right" v-if="!internal">
                <v-text-field class="input-text-right" dense type="number" v-model="_spend" label="Spend" :loading="loading" :disabled="loading" :error-messages="errors" :hide-details="0 == errors.length" @focus="updateTouchOrder('spend')" @blur="handleUnfocus('spend')" @change="pushLatestUpdate">
                    <template v-slot:prepend-inner>
                        <span class="input-text-currency-icon" v-text="$currency.currentSign"></span>
                    </template>
                </v-text-field>
            </validation-provider>
        </td>
        <td class="px-0">
            <validation-provider v-slot="{ errors }" name="CPA" rules="required" class="flex-grow-1 text-right" v-if="!internal">
                <v-text-field class="input-text-right" dense type="number" v-model="_cpa" label="CPA" :loading="loading" :disabled="loading" :error-messages="errors" :hide-details="0 == errors.length" @focus="updateTouchOrder('cpa')" @blur="handleUnfocus('cpa')" @change="pushLatestUpdate">
                    <template v-slot:prepend-inner>
                        <span class="input-text-currency-icon" v-text="$currency.currentSign"></span>
                    </template>
                </v-text-field>
            </validation-provider>
        </td>
        <td class="px-0">
            <validation-provider v-slot="{ errors }" name="FTDs" :rules="rules.ftds" class="flex-grow-1 text-right">
                <v-text-field class="input-text-right" dense type="number" v-model="_ftds" label="FTDs" :loading="loading" :disabled="loading" :error-messages="errors" :hide-details="0 == errors.length" @focus="updateTouchOrder('ftds')" @blur="handleUnfocus('ftds')" @change="pushLatestUpdate"></v-text-field>
            </validation-provider>
        </td>
    </tr>
</template>

<script>
const getCleanNumber = (num) => {
    let ret = 0;
    if ('string' == typeof num) {
        if (num.length == 0) {
            ret = 0;
        }
        if (num.includes('.')) {
            ret = parseFloat(num);
        }
    }
    ret = parseInt(num);
    if (isNaN(ret)) {
        return 0;
    }
    return ret;
}
export default {
    name: 'TargetRow',
    props: {
        spend: {
            type: [String, Number],
            required: true,
        },
        cpa: {
            type: [String, Number],
            required: true,
        },
        ftds: {
            type: [String, Number],
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        offset: {
            type: [String, Number],
            default: 0,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        internal: {
            type: Boolean,
            default: false,
        },
        parent: {
            type: Object,
        },
        children: {
            type: Object,
        },
        parentRef: {
            type: [Object, Array],
        }
    },
    data: () => ({
        u: 0,
        minimums: {
            spend: 0,
            ftds: 0,
        },
        focus: {
            spend: false,
            cpa: false,
            ftds: false,
        },
        touchOrder: [],
        val: {
            spend: 0,
            cpa: 0,
            ftds: 0,
        }
    }),
    computed: {
        rowClass() {
            const classes = new Set();
            classes.add('no-hover');
            if (this.inFocus) {
                classes.add('focused')
            }
            return [...classes].join(' ');
        },
        labelClass() {
            if (0 == this.offset) {
                return '';
            }
            const spacing = this.offset * 2;
            return `ml-${spacing}`;
        },
        _spend: {
            get() {
                this.u;
                return this.val.spend;
            },
            set(val) {
                this.u ++;
                this.$emit('update:spend', getCleanNumber(val));
                // this.$emit('input:spend', getCleanNumber(val));
                // this.$emit('change:spend', getCleanNumber(val));
                // this.updateParent();
                this.val.spend = getCleanNumber(val);
            }
        },
        _cpa: {
            get() {
                this.u;
                return this.val.cpa;
            },
            set(val) {
                this.u ++;
                this.$emit('update:cpa', getCleanNumber(val));
                // this.$emit('input:cpa', getCleanNumber(val));
                // this.$emit('change:cpa', getCleanNumber(val));
                this.val.cpa = getCleanNumber(val);
            }
        },
        _ftds: {
            get() {
                this.u;
                return this.val.ftds;
            },
            set(val) {
                this.u ++;
                this.$emit('update:ftds', getCleanNumber(val));
                // this.$emit('input:ftds', getCleanNumber(val));
                // this.$emit('change:ftds', getCleanNumber(val));
                // this.updateParent();
                this.val.ftds = getCleanNumber(val);
            }
        },
        rules() {
            this.u;
            return {
                spend: ['required', `min_value:${this.minimums.spend}`].join('|'),
                ftds: ['required', `min_value:${this.minimums.ftds}`].join('|'),
            }
        },
        inFocus() {
            let ret = false;
            for (let key in this.focus) {
                if (true == this.focus[key]) {
                    ret = true;
                }
            }
            return ret;
        }
    },
    methods: {
        updateParent() {
            this.$nextTick(() => {
                if (this.parentRef) {
                    if (Array.isArray(this.parentRef)) {
                        for (let i = 0; i < this.parentRef.length; i++) {
                            this.parentRef[i].$emit('updated:children');
                            this.parentRef[i].$emit('updated:child');
                            console.debug(`${this.text} sent 'updated:children' to parentRef ${i}`)
                        }
                    }
                    else {
                        this.parentRef.$emit('updated:children');
                        console.debug(`${this.text} sent 'updated:children' to parentRef`)
                    }
                }
                this.$emit('changed');
            })
        },
        getMinimums() {
            this.u;
            const ret = {
                spend: 0,
                ftds: 0,
            }
            if ('object' == typeof this.children && this.children !== null) {
                for (let key in this.children) {
                    const child = this.children[key];
                    ret.spend += getCleanNumber(child.spend);
                    ret.ftds += getCleanNumber(child.ftds);
                }
            }
            return ret;
        },
        updateTouchOrder(key) {
            const idx = this.touchOrder.indexOf(key);
            if (idx > -1) {
                this.touchOrder.splice(idx, 1);
            }
            this.touchOrder.push(key);
            this.focus[key] = true;
        },
        handleUnfocus(key) {
            this.focus[key] = false;
            this._spend = getCleanNumber(this.val.spend);
            this.$emit('update:spend', getCleanNumber(this.val.spend));
            this._ftds = getCleanNumber(this.val.ftds);
            this.$emit('update:ftds', getCleanNumber(this.val.ftds));
            this._cpa = getCleanNumber(this.val.cpa);
            this.$emit('update:cpa', getCleanNumber(this.val.cpa));
            this.$emit('blurred');
        },
        pushLatestUpdate() {
            this._spend = getCleanNumber(this.val.spend);
            this.$emit('update:spend', getCleanNumber(this.val.spend));
            this._ftds = getCleanNumber(this.val.ftds);
            this.$emit('update:ftds', getCleanNumber(this.val.ftds));
            this._cpa = getCleanNumber(this.val.cpa);
            this.$emit('update:cpa', getCleanNumber(this.val.cpa));
            this.updateParent();
        },
        updateValues() {
            if (this.inFocus) {
                const last2Touches = this.touchOrder.slice(Math.max(this.touchOrder.length - 2, 0))
                const touchKey = last2Touches.join('-');
                switch (touchKey) {
                    case 'spend':
                        console.debug('ftds, cpa');
                        if (this.ftds == 0) {
                            this.updateFTDs();
                        }
                        else {
                            this.updateCPA();
                        }
                        break;

                    case 'cpa':
                        console.debug('ftds, spend');
                        if (this.ftds == 0) {
                            this.updateFTDs();
                        }
                        else {
                            this.updateSpend();
                        }
                        break;

                    case 'ftds':
                        console.debug('spend, cpa');
                        if (this.spend == 0) {
                            this.updateSpend();
                        }
                        else {
                            this.updateCPA();
                        }
                        break;

                    case 'spend-cpa':
                        console.debug('ftds');
                        this.updateFTDs();
                        break;

                    case 'spend-ftds':
                        console.debug('cpa');
                        this.updateCPA();
                        break;

                    case 'cpa-spend':
                        console.debug('ftds');
                        this.updateFTDs();
                        break;

                    case 'cpa-ftds':
                        console.debug('spend');
                        this.updateSpend();
                        break;

                    case 'ftds-spend':
                        console.debug('cpa');
                        this.updateCPA();
                        break;

                    case 'ftds-cpa':
                        console.debug('spend');
                        this.updateSpend();
                        break;
                }
            }
            else {
                console.debug(`Updating CPA for ${this.text} because of external update to spend and/or price`)
                this.updateCPA();
            }
        },
        updateSpend() {
            this._spend = (getCleanNumber(this.val.cpa) * getCleanNumber(this.val.ftds));
        },
        updateCPA() {
            if (getCleanNumber(this.val.ftds) > 0) {
                this._cpa = (getCleanNumber(this.val.spend) / getCleanNumber(this.val.ftds));
            }
            else {
                this._cpa = 0;
            }
        },
        updateFTDs() {
            if (getCleanNumber(this.val.cpa) > 0) {
                this._ftds = (getCleanNumber(this.val.spend) / getCleanNumber(this.val.cpa));
            }
            else {
                this._ftds = 0;
            }
        },
    },
    mounted() {
        this.val.spend = this.spend;
        this.val.cpa = this.cpa;
        this.val.ftds = this.ftds;
        this.$watch('minimums.spend', () => {
            if (this.spend < this.minimums.spend) {
                this.$nextTick(() => {
                    this._spend = this.minimums.spend;
                })
            }
        })
        this.$watch('minimums.ftds', () => {
            if (this.ftds < this.minimums.ftds) {
                this.$nextTick(() => {
                    this._ftds = this.minimums.ftds;
                })
            }
        })
        this.$on('updated:children', () => {
            this.$nextTick(() => {
                console.debug(`${this.text} caught updated:children`)
                this.minimums = this.getMinimums();
                this.u ++;
                this.$nextTick(() => {
                    this.updateParent();
                })
            })
        })
        this.$on('updated:child', () => {
            this.$nextTick(() => {
                console.debug(`${this.text} caught updated:child`)
                this.minimums = this.getMinimums();
                this.u ++;
                this.$nextTick(() => {
                    this.updateParent();
                })
            })
        })
        this.minimums = this.getMinimums();
        this.$nextTick(() => {
            this.u ++;
            this.$emit('mounted:finished');
            this.val.spend = this.spend;
            this.val.cpa = this.cpa;
            this.val.ftds = this.ftds;
        })
    },
    watch: {
        spend(nv) {
            this.val.spend = getCleanNumber(nv);
        },
        cpa(nv) {
            this.val.cpa = getCleanNumber(nv);
        },
        ftds(nv) {
            this.val.ftds = getCleanNumber(nv);
        },
        "val.spend": function(val) {
            this.$emit('update:spend', getCleanNumber(val));
            // this.$emit('input:spend', getCleanNumber(val));
            // this.$emit('change:spend', getCleanNumber(val));
            console.debug(`${this.text} spend updated`)
            this.updateValues();
        },
        "val.ftds": function(val) {
            this.$emit('update:ftds', getCleanNumber(val));
            // this.$emit('input:ftds', getCleanNumber(val));
            // this.$emit('change:ftds', getCleanNumber(val));
            console.debug(`${this.text} ftds updated`)
            this.updateValues();
        },
        "val.cpa": function(val) {
            this.$emit('update:cpa', getCleanNumber(val));
            // this.$emit('input:cpa', getCleanNumber(val));
            // this.$emit('change:cpa', getCleanNumber(val));
            console.debug(`${this.text} cpa updated`)
            this.updateValues();
        },
    }
}
</script>