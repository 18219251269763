<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <adminSidebar></adminSidebar>
         <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <v-toolbar color="toolbar" dense>
                        <v-toolbar-title>Debug Tools</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
                    </v-toolbar>
                    <v-row class="mt-2">
                        <v-col cols="12" md="4" lg="3">
                            <a class="d-block" :disabled="loading"  @click="show = 'notifications'">
                                <v-sheet color="toolbar" class="pa-3 d-flex flex-column elevation-3 clickable" rounded>
                                    <div class="d-flex w-100 justify-center mb-3">
                                        <v-icon x-large>mdi-bell-alert</v-icon>
                                    </div>
                                    <div class="d-flex w-100 justify-center">
                                        <p class="mb-0">Test Notifications</p>
                                    </div>
                                </v-sheet>
                            </a>
                        </v-col>
                        <v-col cols="12" md="4" lg="3">
                            <a class="d-block" :disabled="loading"  @click="testHourlyReport">
                                <v-sheet color="toolbar" class="pa-3 d-flex flex-column elevation-3 clickable" rounded>
                                    <div class="d-flex w-100 justify-center mb-3">
                                        <v-icon x-large>mdi-clock-check-outline</v-icon>
                                    </div>
                                    <div class="d-flex w-100 justify-center">
                                        <p class="mb-0">Test Hourly Report</p>
                                    </div>
                                </v-sheet>
                            </a>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog :value="showing.notifications" max-width="500" persistent>
            <validation-observer slim ref="notificationobserver" v-slot="{ invalid }">
                <v-card color="panel">
                    <v-toolbar color="toolbar" tile flat class="remove-end-padding" dense>
                        <v-toolbar-title>Test Notifications</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon @click="show = null">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-divider color="white"></v-divider>
                    <v-card-text class="pa-3">
                        <validation-provider v-slot="{ errors }" name="Conversion Type" rules="required">
                            <v-autocomplete v-model.trim="forms.notifications.queue" :items="notificationQueues" :loading="loading" :disabled="loading" :error-messages="errors" label="Conversion Type" required :clearable="!loading"></v-autocomplete>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="Country" rules="required">
                            <v-autocomplete v-model.trim="forms.notifications.country" :items="options.countries" :loading="loading" :disabled="loading" :error-messages="errors" label="Country" required :clearable="!loading"></v-autocomplete>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="CRM" rules="required">
                            <v-autocomplete v-model.number="forms.notifications.crm" :items="options.crms" :loading="loading" :disabled="loading" :error-messages="errors" label="CRM" required :clearable="!loading"></v-autocomplete>
                        </validation-provider>
                        <validation-provider v-if="['cold'].indexOf(forms.notifications.queue) > -1" v-slot="{ errors }" name="Department" rules="required">
                            <v-autocomplete v-model.number="forms.notifications.department" :items="options.departments" :loading="loading" :disabled="loading" :error-messages="errors" label="Department" required :clearable="!loading"></v-autocomplete>
                        </validation-provider>
                        <validation-provider v-if="['ftd', 'lead'].indexOf(forms.notifications.queue) > -1" v-slot="{ errors }" name="Publisher" rules="required">
                            <v-autocomplete v-model.number="forms.notifications.publisher" :items="options.publishers" :loading="loading" :disabled="loading" :error-messages="errors" label="Publisher" required :clearable="!loading"></v-autocomplete>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="Users" rules="required">
                            <v-autocomplete v-model="forms.notifications.users" :items="options.users" :loading="loading" :disabled="loading" :error-messages="errors" label="Users" required :clearable="!loading" multiple chips deletable-chips></v-autocomplete>
                        </validation-provider>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="teal" text :loading="loading" @click="closeTestNotification">
                            <span>Finish</span>
                            <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                        </v-btn>
                        <v-btn color="primary" text :disabled="invalid || !$io.connected" :loading="loading" @click="sendTestNotification">
                            <span>Test</span>
                            <v-icon :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" v-text="$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right'"></v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </validation-observer>
        </v-dialog>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../../components/admin/sidebar';
import shortid from 'shortid';
export default {
    components: {
        adminSidebar,
    },
    data: () => ({
        show: null,
        loaded: {
            options: false,
            notification: true,
            report: true,
        },
        options: {
            users: [],
            crms: [],
            departments: [],
            publishers: [],
            countries: [],
        },
        requests: {
            options: null,
        },
        forms: {
            notifications: {
                queue: null,
                country: null,
                crm: null,
                department: null,
                publisher: null,
                users: [],
            },
        }
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        showing() {
            return {
                notifications: ('notifications' == this.show),
            }
        },
        notificationQueues() {
            return [
                {
                    value: 'cold',
                    text: 'Cold FTD',
                },
                {
                    value: 'ftd',
                    text: 'Warm FTD',
                },
                {
                    value: 'lead',
                    text: 'Lead',
                },
            ]
        }
    },
    methods: {
        async loadOptions() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.options = false;
            const id = shortid.generate();
            this.requests.options = id;
            try {
                const res = await this.$io.request('App/Models/System', 'getDebugOptions', [], 60000);
                if (this.requests.options == id) {
                    for (let key in res) {
                        while (this.options[key].length > 0) {
                            this.options[key].splice(0, 1);
                        }
                        for (let i = 0; i < res[key].length; i++) {
                            const item = res[key][i];
                            this.options[key].push(item);
                        }
                    }
                    this.loaded.options = true;
                    this.$emit('options:updated')
                }
            }
            catch (error) {
                if (this.requests.options == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.options = true;
                }
            }
        },
        async testHourlyReport() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.report = false;
            const id = shortid.generate();
            this.requests.report = id;
            try {
                await this.$io.request('App/Models/System', 'testHourlyReport', [], 60000);
                if (this.requests.report == id) {
                    this.loaded.report = true;
                    this.$PNotify.success({
                        title: 'Sent Test Report',
                    })
                }
            }
            catch (error) {
                if (this.requests.report == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.report = true;
                }
            }
        },
        async sendTestNotification() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            if (!this.$refs.notificationobserver) {
                return false;
            }
            const valid = await this.$refs.notificationobserver.validate();
            if (!valid) {
                return false;
            }
            this.loaded.notification = false;
            const id = shortid.generate();
            this.requests.notification = id;
            try {
                await this.$io.request('App/Models/System', 'sendTestNotification', [this.forms.notifications], 60000);
                if (this.requests.notification == id) {
                    this.loaded.notification = true;
                    this.$PNotify.success({
                        title: 'Sent Test Notification',
                    })
                }
            }
            catch (error) {
                if (this.requests.notification == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.notification = true;
                }
            }
        },
        closeTestNotification() {
            this.$refs.notificationobserver.reset()
            this.show = null;
            this.forms.notifications = {
                queue: null,
                country: null,
                crm: null,
                department: null,
                publisher: null,
                users: [],
            }
        }
    },
    mounted() {
        this.loadOptions();
        this.$io.$on('connected', this.loadOptions);
    },
    beforeDestroy() {
        this.$io.$off('connected', this.loadOptions);
    },
}
</script>