<template>
    <v-card color="panel" class="h-100 mb-0">
        <v-toolbar color="toolbar" dense class="remove-padding">
            <v-toolbar-title class="mx-3" v-text="title"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn icon v-if="addTo" :to="addTo">
                    <v-icon>mdi-shape-square-rounded-plus</v-icon>
                </v-btn>
                <v-btn icon @click="load" :loading="loading" :disabled="!$io.connected">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider color="white"></v-divider>
        <v-card-text class="py-3">
            <v-row>
                <v-col cols="12" :sm="sizes.sm" :md="sizes.md" :lg="sizes.lg" :xl="sizes.xl">
                    <validation-observer slim ref="observer" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Details</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.model" icon :disabled="invalid || !$io.connected" @click="saveItem">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <v-card-text v-if="options && Array.isArray(options.fields)">
                                <validation-provider v-slot="{ errors }" v-for="(field, i) in options.fields" :key="i" :name="field.label" :rules="field.rules.join('|')">
                                    <v-text-field v-if="['select', 'boolean', false].indexOf(field.type) == -1" v-bind="fieldBind(field)" v-model.trim="form[field.value]" :loading="!loaded.model" :disabled="!loaded.model" :error-messages="errors" cb="saveItem" @keydown="cbIfEnter"></v-text-field>
                                    <v-autocomplete v-if="'select' == field.type" v-bind="fieldBind(field)" v-model="form[field.value]" :loading="!loaded.model" :disabled="!loaded.model" :error-messages="errors"></v-autocomplete>
                                    <v-switch v-if="['boolean'].indexOf(field.type) > -1" v-model="form[field.value]" :error-messages="errors" :label="field.label" :loading="!loaded.model" :disabled="!loaded.model"></v-switch>
                                </validation-provider>
                            </v-card-text>
                        </v-card>
                    </validation-observer>
                </v-col>
                <v-col v-if="options && options.hasSecurity && !add" cols="12" :sm="sizes.sm" :md="sizes.md" :lg="sizes.lg" :xl="sizes.xl">
                    <validation-observer slim ref="securityObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Security Settings</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.model" icon :disabled="invalid || !$io.connected" @click="saveSec">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <v-card-text class="pa-3">
                                <validation-provider v-slot="{ errors }" name="New Password" rules="required" vid="confirmation">
                                    <v-text-field type="password" v-model.trim="secform.newPass" :loading="!loaded.model" :disabled="!loaded.model" :error-messages="errors" label="New Password" required :clearable="!loaded.model" cb="saveSec" @keydown="cbIfEnter"></v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" name="New Password Confirmation" rules="required|confirmed:confirmation">
                                    <v-text-field type="password" v-model.trim="secform.newPassAgain" :loading="!loaded.model" :disabled="!loaded.model" :error-messages="errors" label="Confirm New Password" required :clearable="!loaded.model" cb="saveSec" @keydown="cbIfEnter"></v-text-field>
                                </validation-provider>
                            </v-card-text>
                            <v-divider color="white"></v-divider>
                            <v-card-actions class="py-3">
                                <v-btn class="flex-grow-1" rounded color="warning darken-1" :dark="true" :disabled="!$io.connected" @click="generateMFA">
                                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 3.277 3.277" preserveAspectRatio="xMidYMid"><path d="M3.277 1.638a1.64 1.64 0 0 1-1.638 1.638A1.64 1.64 0 0 1 0 1.638 1.64 1.64 0 0 1 1.638 0a1.64 1.64 0 0 1 1.638 1.638z" fill="#ec1c24"/><path d="M1.498 1.29l.393.393c.057.057.15.057.206 0s.057-.15 0-.206l-.393-.393C1.422.805.968.8.68 1.07l-.015.014c-.278.295-.274.75.007 1.03l.393.393c.057.057.15.057.206 0s.057-.15 0-.206L.88 1.91c-.17-.17-.173-.45-.004-.623s.45-.168.622.004zm.507-.523c-.057.057-.057.15 0 .206l.393.393c.17.17.173.45.003.623s-.45.168-.622-.004l-.393-.393c-.057-.057-.15-.057-.206 0s-.057.15 0 .206l.393.393c.28.28.735.286 1.023.014l.015-.014c.278-.295.274-.75-.007-1.03L2.21.768c-.057-.057-.15-.057-.206 0z" fill="#fff"/></svg>
                                    Generate MFA
                                </v-btn>
                                <v-btn class="flex-grow-1" rounded color="info darken-2" :dark="true" :disabled="!$io.connected" @click="testMFA">
                                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 3.277 3.277" preserveAspectRatio="xMidYMid"><path d="M3.277 1.638a1.64 1.64 0 0 1-1.638 1.638A1.64 1.64 0 0 1 0 1.638 1.64 1.64 0 0 1 1.638 0a1.64 1.64 0 0 1 1.638 1.638z" fill="#ec1c24"/><path d="M1.498 1.29l.393.393c.057.057.15.057.206 0s.057-.15 0-.206l-.393-.393C1.422.805.968.8.68 1.07l-.015.014c-.278.295-.274.75.007 1.03l.393.393c.057.057.15.057.206 0s.057-.15 0-.206L.88 1.91c-.17-.17-.173-.45-.004-.623s.45-.168.622.004zm.507-.523c-.057.057-.057.15 0 .206l.393.393c.17.17.173.45.003.623s-.45.168-.622-.004l-.393-.393c-.057-.057-.15-.057-.206 0s-.057.15 0 .206l.393.393c.28.28.735.286 1.023.014l.015-.014c.278-.295.274-.75-.007-1.03L2.21.768c-.057-.057-.15-.057-.206 0z" fill="#fff"/></svg>
                                    Test MFA
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </validation-observer>
                </v-col>
            </v-row>
            <v-row v-if="hasSupplemental">
                <v-col :cols="supplementalSizes" v-if="options && options.hasPublishers">
                    <validation-observer slim ref="publishersObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Publishers</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.associations" icon :disabled="!$io.connected" @click="loadAssociations">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn :loading="!loaded.associations" icon :disabled="invalid || !$io.connected" @click="savePublishers">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <checklist height="300" :class="internalCardColor" fixed-header label="Publisher" :items="options.publishers" :loading="!loaded.associations" v-model="pubForm" autoable></checklist>
                        </v-card>
                    </validation-observer>
                </v-col>
                <v-col :cols="supplementalSizes" v-if="options && options.hasCRMs">
                    <validation-observer slim ref="crmsObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>CRMs</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.associations" icon :disabled="!$io.connected" @click="loadAssociations">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn :loading="!loaded.associations" icon :disabled="invalid || !$io.connected" @click="saveCRMs">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <checklist height="300" :class="internalCardColor" fixed-header label="CRM" :items="options.crms" :loading="!loaded.associations" v-model="crmForm" autoable></checklist>
                        </v-card>
                    </validation-observer>
                </v-col>

                <v-col :cols="supplementalSizes" v-if="options && options.hasDepartments">
                    <validation-observer slim ref="deptsObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Departments</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.associations" icon :disabled="!$io.connected" @click="loadAssociations">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn :loading="!loaded.associations" icon :disabled="invalid || !$io.connected" @click="saveDepartments">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <checklist height="300" :class="internalCardColor" fixed-header label="Department" :items="options.departments" :loading="!loaded.associations" v-model="deptForm" autoable :filter="filterDepartments"></checklist>
                        </v-card>
                    </validation-observer>
                </v-col>

                <v-col :cols="supplementalSizes" v-if="options && options.hasUsers">
                    <validation-observer slim ref="usersObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Users</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.associations" icon :disabled="!$io.connected" @click="loadAssociations">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn :loading="!loaded.associations" icon :disabled="invalid || !$io.connected" @click="saveUsers">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <checklist height="300" :class="internalCardColor" fixed-header label="User" :items="options.users" :loading="!loaded.associations" v-model="userForm"></checklist>
                        </v-card>
                    </validation-observer>
                </v-col>
                <v-col :cols="supplementalSizes" v-if="options && options.hasGroups">
                    <validation-observer slim ref="groupsObserver" v-slot="{ invalid }">
                        <v-card :color="internalCardColor" elevation="4">
                            <v-toolbar :color="internalCardToolbarColor" dense class="remove-end-padding">
                                <v-toolbar-title>Groups</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn :loading="!loaded.associations" icon :disabled="!$io.connected" @click="loadAssociations">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn :loading="!loaded.associations" icon :disabled="invalid || !$io.connected" @click="saveGroups">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <checklist height="300" :class="internalCardColor" fixed-header label="Group" :items="options.groups" :loading="!loaded.associations" v-model="groupForm"></checklist>
                        </v-card>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-card-text>
        <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate size="64" color="secondary"></v-progress-circular>
        </v-overlay>
        <v-overlay absolute :value="error.show">
            <v-card color="panel" tile>
                <v-toolbar color="toolbar">
                    <v-toolbar-title class="error--text">Error</v-toolbar-title>
                </v-toolbar>
                <v-divider color="white"></v-divider>
                <v-card-text class="pa-3">
                    <p class="mb-0" v-text="error.text"></p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="load" :loading="loading">
                        Retry
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </v-card>
</template>

<script>
import shortid from 'shortid';
import merge from 'lodash.merge';
import dot from 'dot-object';
import checklist from '../general/checklist';
export default {
    components: {
        checklist,
    },
    props: {
        model: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            default: 'Model',
        },
        addTo: {
            type: Object,
        },
        viewTo: {
            type: Object,
        },
        add: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [Number, String],
        }
    },
    data: () => ({
        loaded: {
            fields: false,
            model: false,
            associations: false,
        },
        form: {},
        secform: {
            newPass: '',
            newPassAgain: '',
        },
        pubForm: [],
        crmForm: [],
        deptForm: true,
        userForm: [],
        groupForm: [],
        instance: {},
        options: {},
        requests: {
            loadFields: null,
        },
        error: {
            show: false,
            text: '',
        },
        timeouts: {}
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        title() {
            if (this.add) {
                return `Add ${this.modelName}`;
            }
            else if (this.loading) {
                return `Loading ${this.modelName}`;
            }
            else if (this.options && this.options.displayKey) {
                const display = dot.pick(this.options.displayKey, this.instance, false);
                if (display) {
                    return `Manage ${display}`;
                }
            }
            return `Manage ${this.modelName}`;
        },
        sizes() {
            if (this.options && this.options.hasSecurity && !this.add) {
                return {
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                }
            }
            else {
                return {
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                }
            }
        },
        internalCardColor() {
            return (this.$vuetify.theme.dark) ? 'panel lighten-1' : 'panel darken-1'
        },
        internalCardToolbarColor() {
            return (this.$vuetify.theme.dark) ? 'toolbar lighten-1' : 'toolbar darken-1'
        },
        hasSupplemental() {
            if (this.add) {
                return false;
            }
            return ([
                this.options.hasUsers,
                this.options.hasCRMs,
                this.options.hasDepartments,
                this.options.hasPublishers,
                this.options.hasGroups,
            ].filter((v) => {
                return (true == v);
            }).length > 0);
        },
        supplementalSizes() {
            if (this.add || this.$vuetify.breakpoint.xsOnly) {
                return 12;
            }
            const truths = [
                this.options.hasUsers,
                this.options.hasCRMs,
                this.options.hasDepartments,
                this.options.hasPublishers,
                this.options.hasGroups,
            ].filter((v) => {
                return (true == v);
            }).length;
            const ret = Math.ceil(12 / truths);
            if (ret < 4) {
                return 4;
            }
            return ret;
        }
    },
    methods: {
        fieldBind(field) {
            const ret = {
                label: field.label,
                type: field.type,
            };
            if (field.readonly) {
                ret.readonly = true;
            }
            if (Array.isArray(field.items)) {
                ret.items = field.items;
            }
            return ret;
        },
        async load() {
            await this.loadFields();
            this.$nextTick(() => {
                this.loadModel().then(() => {
                    this.$nextTick(() => {
                        this.loadAssociations();
                    })
                });
            });
        },
        async loadFields() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loaded.fields = false;
            const id = shortid.generate();
            this.requests.loadFields = id;
            try {
                const res = await this.$io.request(this.model, 'getFields', [this.add], 60000);
                if (this.requests.loadFields == id) {
                    this.options = res;
                    this.$emit('options:updated')
                    this.loaded.fields = true;
                }
            }
            catch (error) {
                if (this.requests.loadFields == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loaded.fields = true;
                }
            }
        },
        async loadModel() {
            if (!this.$io.connected) {
                return false;
            }
            if (this.add) {
                this.loaded.model = true;
                return;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.loadModel = id;
            try {
                const res = await this.$io.request(this.model, 'viewResource', [this.id], 60000);
                if (this.requests.loadModel == id) {
                    this.$emit('model:updated')
                    this.instance = res;
                    this.loaded.model = true;
                }
            }
            catch (error) {
                if (this.requests.loadModel == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loaded.model = true;
                }
            }
        },
        async loadAssociations() {
            if (!this.$io.connected) {
                return false;
            }
            if (this.add || !this.hasSupplemental) {
                this.loaded.associations = true;
                return;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.loadAssociations = id;
            try {
                const res = await this.$io.request(this.model, 'viewResourceAssociations', [this.id], 60000);
                if (this.requests.loadAssociations == id) {
                    const {pubForm, crmForm, userForm, groupForm, deptForm} = res;
                    this.updateFormWithItems('pubForm', pubForm);
                    this.updateFormWithItems('crmForm', crmForm);
                    this.updateFormWithItems('userForm', userForm);
                    this.updateFormWithItems('groupForm', groupForm);
                    this.updateFormWithItems('deptForm', deptForm);
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.loadAssociations == id) {
                    this.error.show = true;
                    this.error.text = error.toString();
                    this.loaded.associations = true;
                }
            }
        },
        async saveItem() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.observer.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.saveModel = id;
            try {
                const uid = await this.$io.request(this.model, 'saveResource', [this.id, this.form], 60000);
                if (this.requests.saveModel == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Details Successfully',
                    })
                    if (this.add) {
                        const route = merge({}, this.viewTo, {
                            params: {
                                id: uid,
                            },
                        })
                        this.$router.push(route, () => {}, () => {});
                    }
                    else {
                        this.loaded.model = true;
                    }
                }
            }
            catch (error) {
                if (this.requests.saveModel == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadModel();
                }
            }
        },
        async saveSec() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.securityObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.securityObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.saveSec = id;
            try {
                await this.$io.request(this.model, 'saveResourceSecurity', [this.id, this.secform], 60000);
                if (this.requests.saveSec == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Security Details Successfully',
                    })
                    this.secform.newPass = '';
                    this.secform.newPassAgain = '';
                    await this.$refs.securityObserver.reset();
                    this.loaded.model = true;
                }
            }
            catch (error) {
                if (this.requests.saveSec == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadModel();
                }
            }
        },
        async generateMFA() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.generateMFA = id;
            try {
                await this.$io.request(this.model, 'generateMFA', [this.id], 60000);
                if (this.requests.generateMFA == id) {
                    this.loaded.model = true;
                    this.$PNotify.info({
                        title: 'Request Received',
                        text: 'MFA Details are being generated'
                    })
                }
            }
            catch (error) {
                if (this.requests.generateMFA == id) {
                    this.$PNotify.error({
                        title: 'Request Failed',
                        text: error.toString(),
                    })
                    this.loaded.model = true;
                }
            }
        },
        async testMFA() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.testMFA = id;
            try {
                await this.$io.request(this.model, 'testMFA', [this.id], 60000);
                if (this.requests.testMFA == id) {
                    this.loaded.model = true;
                    this.$PNotify.info({
                        title: 'Request Received',
                        text: 'User should receive a token or notification with code'
                    })
                }
            }
            catch (error) {
                if (this.requests.testMFA == id) {
                    this.$PNotify.error({
                        title: 'Request Failed',
                        text: error.toString(),
                    })
                    this.loaded.model = true;
                }
            }
        },
        updateForm() {
            // clear existing keys on the form
            const keys = Object.keys(this.form);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                delete this.form[key];
            }
            // add keys from options & populate values from model
            if (this.options && Array.isArray(this.options.fields)) {
                for (let i = 0; i < this.options.fields.length; i++) {
                    const field = this.options.fields[i];
                    if ('undefined' !== typeof this.instance[field.value]) {
                        this.form[field.value] = this.instance[field.value];
                    }
                    else {
                        this.form[field.value] = field.default;
                    }
                }
            }
        },
        updateFormWithItems(form, items) {
            if (Array.isArray(items)) {
                if (!Array.isArray(this[form])) {
                    this[form] = [];
                }
                else {
                    while (this[form].length > 0) {
                        this[form].splice(0, 1);
                    }
                    for (let i = 0; i < items.length; i++) {
                        const value = items[i];
                        this[form].push(value);
                    }
                }
            }
            else {
                this[form] = items;
            }
        },
        filterDepartments(department) {
            if (true == this.crmForm) {
                return true;
            }
            return (this.crmForm.indexOf(department.crm_id) > -1);
        },
        async savePublishers() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.publishersObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.publishersObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.savePublishers = id;
            try {
                await this.$io.request(this.model, 'saveAssociatedPublishers', [this.id, this.pubForm], 60000);
                if (this.requests.savePublishers == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Publisher Associations Successfully',
                    })
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.savePublishers == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadAssociations();
                }
            }
        },
        async saveCRMs() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.crmsObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.crmsObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.saveCRMs = id;
            try {
                await this.$io.request(this.model, 'saveAssociatedCRMs', [this.id, this.crmForm], 60000);
                if (this.requests.saveCRMs == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved CRM Associations Successfully',
                    })
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.saveCRMs == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadAssociations();
                }
            }
        },
        async saveDepartments() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.deptsObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.deptsObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.saveDepartments = id;
            try {
                await this.$io.request(this.model, 'saveAssociatedDepartments', [this.id, this.deptForm], 60000);
                if (this.requests.saveDepartments == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Department Associations Successfully',
                    })
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.saveDepartments == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadAssociations();
                }
            }
        },
        async saveUsers() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.usersObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.usersObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.saveUsers = id;
            try {
                await this.$io.request(this.model, 'saveAssociatedUsers', [this.id, this.userForm], 60000);
                if (this.requests.saveUsers == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved User Associations Successfully',
                    })
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.saveUsers == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadAssociations();
                }
            }
        },
        async saveGroups() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.groupsObserver.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.groupsObserver.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.associations = false;
            const id = shortid.generate();
            this.requests.saveGroups = id;
            try {
                await this.$io.request(this.model, 'saveAssociatedGroups', [this.id, this.groupForm], 60000);
                if (this.requests.saveGroups == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Group Associations Successfully',
                    })
                    this.loaded.associations = true;
                }
            }
            catch (error) {
                if (this.requests.saveGroups == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadAssociations();
                }
            }
        },
    },
    mounted() {
        this.timeouts.initial = setTimeout(() => {
            this.load();
        }, 250);
        this.$io.$on('connected', this.load);
        this.$on('options:updated', () => {
            this.$nextTick(() => {
                this.updateForm();
            })
        })
        this.$on('model:updated', () => {
            this.$nextTick(() => {
                this.updateForm();
            })
        })
    },
    beforeDestroy() {
        for (let i in this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
        this.$io.$off('connected', this.load);
    },
}
</script>