<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <adminSidebar></adminSidebar>
         <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <editResource v-bind="$route.meta.edit" :id="$route.params.id"></editResource>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../../components/admin/sidebar';
import editResource from '../../../components/resources/edit';
export default {
    components: {
        adminSidebar,
        editResource
    }
}
</script>