<template>
    <v-navigation-drawer fixed app clipped permenant :left="!$vuetify.rtl" :right="$vuetify.rtl" dark color="toolbar" :value="show" :mini-variant="$vuetify.breakpoint.mdAndDown" :expand-on-hover="$vuetify.breakpoint.mdAndDown" :mini-variant-width="10" :mobile-breakpoint="$vuetify.breakpoint.mdAndDown ? 0 : null">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text">Administration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list dense class="py-0">
        <v-list-item v-for="(nav, i) in navs" :key="i" :to="nav.to" @click="nav.cb" :exact="nav.exact">
            <v-list-item-avatar>
                <v-icon v-text="nav.icon"></v-icon>
            </v-list-item-avatar>  
            <v-list-item-content>
                <v-list-item-subtitle v-text="nav.title"></v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        navs() {
            const routes = {};
            for (let i = 0; i < this.$router.options.routes.length; i++) {
                const route = this.$router.options.routes[i];
                routes[route.name] = (route.meta) ? route.meta.permissions : null;
            }
            return [
                {
                    title: 'Admin Index',
                    icon: 'mdi-cogs',
                    cb: () => {},
                    to: {
                        name: 'admin/list'
                    },
                    exact: true,
                },
                {
                    title: 'User Management',
                    icon: 'mdi-account-group',
                    cb: () => {},
                    to: {
                        name: 'admin/users/list'
                    }
                },
                {
                    title: 'Group Management',
                    icon: 'mdi-group',
                    cb: () => {},
                    to: {
                        name: 'admin/groups/list'
                    }
                },
                {
                    title: 'Target Management',
                    icon: 'mdi-target-variant',
                    cb: () => {},
                    to: {
                        name: 'admin/targets/list'
                    }
                },
                {
                    title: 'Filter Management',
                    icon: 'mdi-filter',
                    cb: () => {},
                    to: {
                        name: 'admin/filters/list'
                    }
                },
                {
                    title: 'Chatbot Management',
                    icon: 'mdi-message-processing',
                    cb: () => {},
                    to: {
                        name: 'admin/chatbots/list'
                    }
                },
                {
                    title: 'Message Templates',
                    icon: 'mdi-message-text',
                    cb: () => {},
                    to: {
                        name: 'admin/chatbots/messages'
                    }
                },
                {
                    title: 'Debugging',
                    icon: 'mdi-bug-check',
                    cb: () => {},
                    to: {
                        name: 'admin/debug/index'
                    }
                }
            ].filter((v) => {
                const permissions = routes[v.to.name];
                if (null == permissions) {
                    return true;
                }
                let can = true;
                for (let i = 0; i < permissions.length; i++) {
                    const perm = permissions[i];
                    if (!this.$auth.can(perm)) {
                        can = false;
                    }
                }
                return can;
            });
        }
    },
    mounted() {
        console.log()
    }
}
</script>