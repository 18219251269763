<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <adminSidebar></adminSidebar>
         <v-container class="h-100 d-flex w-100 align-stretch flex-column" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <validation-observer slim ref="observer">
                        <v-card color="panel" class="h-100 mb-0">
                            <v-toolbar color="toolbar" dense class="remove-padding" flat tile>
                                <v-toolbar-title class="mx-3" v-text="title"></v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn icon @click="copyModel" :loading="loading" :disabled="!$io.connected" v-if="!$route.meta.edit.add">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="saveModel" :loading="loading" :disabled="!$io.connected">
                                        <v-icon>mdi-content-save</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="load" :loading="loading" :disabled="!$io.connected">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider color="white"></v-divider>
                            <v-card-text class="pa-3">
                                <validation-provider v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field v-model.trim="form.name" :loading="loading" :disabled="loading" :error-messages="errors" label="Name" required :clearable="!loading" cb="saveModel" @keydown="cbIfEnter"></v-text-field>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" name="Driver" rules="required">
                                    <v-autocomplete v-model.trim="form.driver" :items="options" :loading="loading" :disabled="loading" :error-messages="errors" label="Driver" required :clearable="!loading" cb="saveModel" @keydown="cbIfEnter"></v-autocomplete>
                                </validation-provider>
                                <validation-provider v-slot="{ errors }" name="Enabled" rules="required">
                                    <v-switch v-model="form.is_enabled" :error-messages="errors" label="Enabled" :loading="loading" :disabled="loading"></v-switch>
                                </validation-provider>
                                <label class="d-block mb-1">Configuration:</label>
                                <editor v-model="psuedo" @init="editorInit" lang="json" theme="chrome" width="100%" height="300" v-if="loaded.model"></editor>
                            </v-card-text>
                        </v-card>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../../components/admin/sidebar';
import shortid from 'shortid';
import merge from 'lodash.merge';
import dot from 'dot-object';
import moment from 'moment';
export default {
    components: {
        adminSidebar,
        editor: require('vue2-ace-editor'),
    },
    data: () => ({
        loaded: {
            options: false,
            model: false,
            first: false,
            // associations: false,
        },
        firstLoaded: {
            options: false,
            model: false,
            // associations: false,
        },
        form: {
            name: '',
            driver: '',
            configuration: {},
            is_enabled: false,
        },
        instance: {
            name: '',
            driver: '',
            configuration: {},
            is_enabled: false,
        },
        requests: {
            loadFields: null,
        },
        error: {
            show: false,
            text: '',
        },
        timeouts: {},
        options: [],
        u: 0,
        uu: 0,
        psuedo: '{}',
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        firstLoading() {
            let ret = false;
            for (let key in this.firstLoaded) {
                if (false == this.firstLoaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        title() {
            if (this.$route.meta.edit.add) {
                return `Add ${this.$route.meta.edit.modelName}`;
            }
            else if (this.loading) {
                return `Loading ${this.$route.meta.edit.modelName}`;
            }
            else if (this.$route.meta.edit.options && this.$route.meta.edit.options.displayKey) {
                const display = dot.pick(this.$route.meta.edit.options.displayKey, this.instance, false);
                if (display) {
                    return `Manage ${display}`;
                }
            }
            return `Manage ${this.$route.meta.edit.modelName}`;
        },
        internalCardColor() {
            return (this.$vuetify.theme.dark) ? 'panel lighten-1' : 'panel darken-1'
        },
        internalCardToolbarColor() {
            return (this.$vuetify.theme.dark) ? 'toolbar lighten-1' : 'toolbar darken-1'
        },
        months() {
            const ret = [];
            let i = 0;
            while (i < 12) {
                const m = moment().month(i);
                ret.push({
                    value: i,
                    text: m.format('MMMM'),
                })
                i ++;
            }
            return ret;
        },
        _refs() {
            this.u;
            return this.$refs;
        }
    },
    methods: {
        editorInit: function () {
            require('brace/ext/language_tools')
            require('brace/mode/json')                
            require('brace/theme/chrome')
        },
        updateConfigurationJSON() {
            this.psuedo = JSON.stringify(this.form.configuration, null, 2);
        },
        async load() {
            const promises = [
                this.loadOptions(),
                this.loadModel(),
                this.loadModelForCopy(),
            ];
            if (promises.length > 0) {
                await Promise.all(promises);
            }
            this.updateConfigurationJSON();
        },
        async loadOptions() {
            if (!this.$io.connected) {
                return false;
            }
            this.error.show = false;
            this.loaded.options = false;
            const id = shortid.generate();
            this.requests.loadOptions = id;
            try {
                const res = await this.$io.request(this.$route.meta.edit.model, 'getDrivers', [], 60000);
                if (this.requests.loadOptions == id) {
                    this.options = res;
                    this.$emit('options:updated')
                    this.loaded.options = true;
                    this.firstLoaded.options = true;
                }
            }
            catch (error) {
                if (this.requests.loadOptions == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.options = true;
                    this.firstLoaded.options = true;
                }
            }
        },
        async loadModel() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            if (this.$route.meta && this.$route.meta.edit && this.$route.meta.edit.add) {
                this.loaded.model = true;
                this.firstLoaded.model = true;
                return;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.loadModel = id;
            try {
                const res = await this.$io.request(this.$route.meta.edit.model, 'viewResource', [this.$route.params.id], 60000);
                if (this.requests.loadModel == id) {
                    this.instance = res;
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                    this.$emit('model:updated')
                }
            }
            catch (error) {
                if (this.requests.loadModel == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                }
            }
        },
        async loadModelForCopy() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            if (this.$route.meta && this.$route.meta.edit && !this.$route.meta.edit.add) {
                return;
            }
            if (!this.$route.query || !this.$route.query.copyFrom) {
                return;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.loadModel = id;
            try {
                const res = await this.$io.request(this.$route.meta.edit.model, 'viewResource', [this.$route.query.copyFrom], 60000);
                if (this.requests.loadModel == id) {
                    this.instance.name = '';
                    this.instance.driver = '';
                    this.instance.configuration = res.configuration;
                    this.instance.is_enabled = res.is_enabled;
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                    this.$emit('model:updated')
                }
            }
            catch (error) {
                if (this.requests.loadModel == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.model = true;
                    this.firstLoaded.model = true;
                }
            }
        },
        async saveModel() {
            if (!this.$io.connected) {
                return false;
            }
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                console.warn(`Got Errors:`, this.$refs.observer.errors);
                return false;
            }
            this.error.show = false;
            this.loaded.model = false;
            const id = shortid.generate();
            this.requests.saveModel = id;
            try {
                const uid = await this.$io.request(this.$route.meta.edit.model, 'saveResource', [this.$route.params.id, this.form], 60000);
                if (this.requests.saveModel == id) {
                    this.$PNotify.info({
                        title: 'Saved',
                        text: 'Saved Chatbot Successfully',
                    })
                    if (this.$route.meta.edit.add) {
                        const route = merge({}, this.$route.meta.edit.viewTo, {
                            params: {
                                id: uid,
                            },
                        })
                        this.$router.push(route, () => {}, () => {});
                    }
                    else {
                        this.loaded.model = true;
                    }
                }
            }
            catch (error) {
                if (this.requests.saveModel == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    await this.loadModel();
                }
            }
        },
        copyModel() {
            this.$router.push({
                name: 'admin/chatbots/add',
                query: {
                    copyFrom: this.$route.params.id,
                }
            })
        },
        updateForm() {
            this.form.configuration = this.instance.configuration;
            this.form.name = this.instance.name;
            this.form.driver = this.instance.driver;
            this.form.is_enabled = this.instance.is_enabled;
            this.$nextTick(() => {
                this.u ++;
            })
        },
        headerCellClass(additional) {
            const ignore = [
                'v-data-table',
                'v-data-table--dense',
                'v-data-table--fixed-header',
                'theme--dark',
                'theme--light',
            ];
            const ret = new Set();
            if (this.$el && this.$el.classList) {
                [...this.$el.classList].filter((v) => {
                    return (ignore.indexOf(v) == -1);
                }).map((v) => {
                    ret.add(v);
                })
            }
            if (Array.isArray(additional)) {
                for (let i = 0; i < additional.length; i++) {
                    const add = additional[i];
                    ret.add(add);
                }
            }
            else if ('string' == typeof additional) {
                const parts = additional.split(' ');
                for (let i = 0; i < parts.length; i++) {
                    const part = parts[i];
                    ret.add(part);
                }
            }
            return [...ret].filter((v) => {
                return ('string' == typeof v);
            }).map((v) => {
                return v.trim();
            }).join(' ');
        },
        recalculate() {},
        onBlurred() {}
    },
    mounted() {
        this.timeouts.initial = setTimeout(() => {
            this.load();
        }, 250);
        this.$io.$on('connected', this.load);
        this.$on('options:updated', this.updateForm)
        this.$on('model:updated', this.updateForm)
        this.$nextTick(() => {
            this.u ++;
        })
        this.$watch('loading', () => {
            this.$nextTick(() => {
                this.u ++;
            })
        })
    },
    beforeDestroy() {
        for (let i in this.timeouts) {
            clearTimeout(this.timeouts[i]);
        }
        this.$io.$off('connected', this.load);
    },
    watch: {
        firstLoading() {
            console.debug('firstLoading', this.firstLoading);
            clearTimeout(this.timeouts.firstLoad);
            this.timeouts.firstLoad = setTimeout(() => {
                this.loaded.first = (false == this.firstLoading);
            }, 500)
        },
        "loaded.first": function() {
            this.$nextTick(() => {
                this.u ++;
            })
        },
        "form.driver": function(nv) {
            const driver = this.options.filter((v) => {
                return (v.value == nv);
            }).shift();
            if (driver) {
                const existing = {};
                const keys = Object.keys(this.form.configuration);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (Object.keys(driver.configuration).indexOf(key) > -1) {
                        existing[key] = this.form.configuration[key];
                    }
                }
                this.form.configuration = merge({}, driver.configuration, existing);
            }
        },
        'form.configuration': function() {
            if (JSON.parse(this.psuedo) !== this.form.configuration) {
                this.updateConfigurationJSON();
            }
        },
        psuedo() {
            if (this.configuration !== JSON.stringify(this.form.configuration)) {
                try {
                    this.form.configuration = JSON.parse(this.psuedo);
                }
                catch (error) {
                    // do nothing
                }
            }
        },
    }
}
</script>

<style lang="scss">
    .input-text-currency-icon {
        position: relative;
        top: 6px;
    }
</style>