var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"fill-height",staticStyle:{"position":"relative"},attrs:{"color":"background"}},[_c('adminSidebar'),_c('v-container',{staticClass:"h-100 d-flex w-100 align-stretch",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-stretch justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"color":"toolbar","dense":""}},[_c('v-toolbar-title',[_vm._v("Debug Tools")]),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('a',{staticClass:"d-block",attrs:{"disabled":_vm.loading},on:{"click":function($event){_vm.show = 'notifications'}}},[_c('v-sheet',{staticClass:"pa-3 d-flex flex-column elevation-3 clickable",attrs:{"color":"toolbar","rounded":""}},[_c('div',{staticClass:"d-flex w-100 justify-center mb-3"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-bell-alert")])],1),_c('div',{staticClass:"d-flex w-100 justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Test Notifications")])])])],1)]),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"3"}},[_c('a',{staticClass:"d-block",attrs:{"disabled":_vm.loading},on:{"click":_vm.testHourlyReport}},[_c('v-sheet',{staticClass:"pa-3 d-flex flex-column elevation-3 clickable",attrs:{"color":"toolbar","rounded":""}},[_c('div',{staticClass:"d-flex w-100 justify-center mb-3"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-clock-check-outline")])],1),_c('div',{staticClass:"d-flex w-100 justify-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Test Hourly Report")])])])],1)])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.showing.notifications,"max-width":"500","persistent":""}},[_c('validation-observer',{ref:"notificationobserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"color":"panel"}},[_c('v-toolbar',{staticClass:"remove-end-padding",attrs:{"color":"toolbar","tile":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Test Notifications")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-card-text',{staticClass:"pa-3"},[_c('validation-provider',{attrs:{"name":"Conversion Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.notificationQueues,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Conversion Type","required":"","clearable":!_vm.loading},model:{value:(_vm.forms.notifications.queue),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "queue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"forms.notifications.queue"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.options.countries,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Country","required":"","clearable":!_vm.loading},model:{value:(_vm.forms.notifications.country),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "country", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"forms.notifications.country"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"CRM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.options.crms,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"CRM","required":"","clearable":!_vm.loading},model:{value:(_vm.forms.notifications.crm),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "crm", _vm._n($$v))},expression:"forms.notifications.crm"}})]}}],null,true)}),(['cold'].indexOf(_vm.forms.notifications.queue) > -1)?_c('validation-provider',{attrs:{"name":"Department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.options.departments,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Department","required":"","clearable":!_vm.loading},model:{value:(_vm.forms.notifications.department),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "department", _vm._n($$v))},expression:"forms.notifications.department"}})]}}],null,true)}):_vm._e(),(['ftd', 'lead'].indexOf(_vm.forms.notifications.queue) > -1)?_c('validation-provider',{attrs:{"name":"Publisher","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.options.publishers,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Publisher","required":"","clearable":!_vm.loading},model:{value:(_vm.forms.notifications.publisher),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "publisher", _vm._n($$v))},expression:"forms.notifications.publisher"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Users","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.options.users,"loading":_vm.loading,"disabled":_vm.loading,"error-messages":errors,"label":"Users","required":"","clearable":!_vm.loading,"multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.forms.notifications.users),callback:function ($$v) {_vm.$set(_vm.forms.notifications, "users", $$v)},expression:"forms.notifications.users"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"teal","text":"","loading":_vm.loading},on:{"click":_vm.closeTestNotification}},[_c('span',[_vm._v("Finish")]),_c('v-icon',{class:_vm.$vuetify.rtl ? 'mr-2' : 'ml-2',domProps:{"textContent":_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right')}})],1),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":invalid || !_vm.$io.connected,"loading":_vm.loading},on:{"click":_vm.sendTestNotification}},[_c('span',[_vm._v("Test")]),_c('v-icon',{class:_vm.$vuetify.rtl ? 'mr-2' : 'ml-2',domProps:{"textContent":_vm._s(_vm.$vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right')}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }