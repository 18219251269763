<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <adminSidebar></adminSidebar>
         <v-container class="h-100 d-flex w-100 align-stretch" fluid>
            <v-row class="align-stretch justify-center">
                <v-col cols="12">
                    <v-card color="panel">
                        <v-toolbar color="toolbar" tile flat class="remove-end-padding" dense>
                            <v-toolbar-title>Message Templates</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <!-- <v-btn icon @click="show = null">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn> -->
                                <v-btn icon @click="loadTexts" :loading="loading" :disabled="!$io.connected">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                                <v-btn icon @click="saveTexts" :loading="loading" :disabled="!$io.connected">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-card-text class="pa-3">
                            <v-row>
                                <v-col cols="12" md="9" class="py-0">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <label class="d-block mb-3 mt-1">Lead Notification</label>
                                            <editor v-model="payload.lead" @init="editorInit" lang="text" theme="chrome" width="100%" height="300"></editor>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label class="d-block mb-3 mt-1">Warm FTD Notification</label>
                                            <editor v-model="payload.ftd" @init="editorInit" lang="text" theme="chrome" width="100%" height="300"></editor>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <label class="d-block mb-3 mt-1">Cold FTD Notification</label>
                                            <editor v-model="payload.cold" @init="editorInit" lang="text" theme="chrome" width="100%" height="300"></editor>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="pa-0 pb-3">
                                            <v-simple-table class="panel" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>Variable</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(desc, key) in conversionVars" :key="key">
                                                            <td><code v-html="`{{${key}}}`"></code></td>
                                                            <td>{{desc}}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="3" class="py-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <label class="d-block mb-3 mt-1">Hourly Report</label>
                                            <editor v-model="payload.report" @init="editorInit" lang="text" theme="chrome" width="100%" height="300"></editor>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" class="pa-0 pb-3">
                                            <v-simple-table class="panel" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>Variable</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(desc, key) in reportVars" :key="key">
                                                            <td><code v-html="`{{${key}}}`"></code></td>
                                                            <td>{{desc}}</td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../../components/admin/sidebar';
import shortid from 'shortid';
export default {
    components: {
        adminSidebar,
        editor: require('vue2-ace-editor'),
    },
    data: () => ({
        loaded: {
            texts: false,
        },
        requests: {
            texts: null,
        },
        payload: {
            lead: '',
            ftd: '',
            cold: '',
            report: '',
        }
    }),
    computed: {
        loading() {
            let ret = false;
            for (let key in this.loaded) {
                if (false == this.loaded[key]) {
                    ret = true;
                }
            }
            return ret;
        },
        conversionVars() {
            return {
                'id': 'The ID of the Conversion',
                'queue': 'The Type of Conversion',
                'country': 'The ISO2 of the Country of the Conversion',
                'country_name': 'The name of the Country of the Conversion',
                'details': 'Additional Information related to the Conversion from the Source CRM',
                'converted_at': 'The Raw Timestamp of the Conversion',
                'converted_at_formatted': 'The Pretty Formatted Timestamp of the Conversion',
                'crm.id': 'The System ID of the CRM',
                'crm.name': 'The System Name of the CRM',
                'department.id': 'The System ID of the Department',
                'department.name': 'The System Name of the Department',
                'department.crm_id': 'The System ID of the CRM of the Department',
                'publisher.id': 'The System ID of the Publisher',
                'publisher.name': 'The System Name of the Publisher',
                'publisher.type': 'The Type of Publisher',
                'publisher.type_formatted': 'The Formatted Type of Publisher',
                'campaign.id': 'The System ID of the Campaign',
                'campaign.name': 'The System Name of the Campaign',
            }
        },
        reportVars() {
            return {
                'day.timestamp': 'The Formatted Date of the Report',
                'day.spend': 'The total spend for the day',
                'day.cpi': 'The cost per impression for the day',
                'day.cpc': 'The cost per click for the day',
                'day.cpl': 'The cost per lead for the day',
                'day.cpa': 'The cost per FTD of the day',
                'day.impressions': 'The total number of impressions for the day',
                'day.ctr': 'The Click Through Rate for the day',
                'day.itl': 'The Impression to Lead Rate for the day',
                'day.ita': 'The Impression to FTD Rate for the day',
                'day.clicks': 'The total number of clicks for the day',
                'day.ctl': 'The Click to Lead Rate for the day',
                'day.cta': 'The Click to FTD Rate for the day',
                'day.leads': 'The total number of leads for the day',
                'day.cr': 'The Conversion Rate for the day',
                'day.ftds': 'The total number of FTDs for the day',
                'month.start': 'The Start Date of the Month',
                'month.end': 'The End Date of the Month',
                'month.name': 'The Name of the Month',
                'month.spend': 'The total spend for the month',
                'month.cpi': 'The cost per impression for the month',
                'month.cpc': 'The cost per click for the month',
                'month.cpl': 'The cost per lead for the month',
                'month.cpa': 'The cost per FTD of the month',
                'month.impressions': 'The total number of impressions for the month',
                'month.ctr': 'The Click Through Rate for the month',
                'month.itl': 'The Impression to Lead Rate for the month',
                'month.ita': 'The Impression to FTD Rate for the month',
                'month.clicks': 'The total number of clicks for the month',
                'month.ctl': 'The Click to Lead Rate for the month',
                'month.cta': 'The Click to FTD Rate for the month',
                'month.leads': 'The total number of leads for the month',
                'month.cr': 'The Conversion Rate for the month',
                'month.ftds': 'The total number of FTDs for the month',
            };
        }
    },
    methods: {
        editorInit: function (editor) {
            require('brace/ext/language_tools')
            require('brace/mode/text')                
            require('brace/theme/chrome')
            editor.setShowPrintMargin(false);
            editor.renderer.setShowGutter(false);
        },
        async loadTexts() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.texts = false;
            const id = shortid.generate();
            this.requests.texts = id;
            try {
                const res = await this.$io.request('App/Models/Chatbot', 'getTexts', [], 60000);
                if (this.requests.texts == id) {
                    const {lead, ftd, cold, report} = res;
                    this.payload.lead = lead;
                    this.payload.ftd = ftd;
                    this.payload.cold = cold;
                    this.payload.report = report;
                    this.loaded.texts = true;
                    this.$emit('texts:updated')
                }
            }
            catch (error) {
                if (this.requests.texts == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.texts = true;
                }
            }
        },
        async saveTexts() {
            if (!this.$io.connected || !this.$route.meta) {
                return false;
            }
            this.loaded.texts = false;
            const id = shortid.generate();
            this.requests.texts = id;
            try {
                await this.$io.request('App/Models/Chatbot', 'saveTexts', [this.payload], 60000);
                if (this.requests.texts == id) {
                    this.loaded.texts = true;
                }
            }
            catch (error) {
                if (this.requests.texts == id) {
                    this.$PNotify.error({
                        title: 'Error',
                        text: error.toString(),
                    })
                    this.loaded.texts = true;
                }
            }
        },
        // async sendTestNotification() {
        //     if (!this.$io.connected || !this.$route.meta) {
        //         return false;
        //     }
        //     if (!this.$refs.notificationobserver) {
        //         return false;
        //     }
        //     const valid = await this.$refs.notificationobserver.validate();
        //     if (!valid) {
        //         return false;
        //     }
        //     this.loaded.notification = false;
        //     const id = shortid.generate();
        //     this.requests.notification = id;
        //     try {
        //         await this.$io.request('App/Models/System', 'sendTestNotification', [this.forms.notifications], 60000);
        //         if (this.requests.notification == id) {
        //             this.loaded.notification = true;
        //             this.$PNotify.success({
        //                 title: 'Sent Test Notification',
        //             })
        //         }
        //     }
        //     catch (error) {
        //         if (this.requests.notification == id) {
        //             this.$PNotify.error({
        //                 title: 'Error',
        //                 text: error.toString(),
        //             })
        //             this.loaded.notification = true;
        //         }
        //     }
        // },
        // closeTestNotification() {
        //     this.$refs.notificationobserver.reset()
        //     this.show = null;
        //     this.forms.notifications = {
        //         queue: null,
        //         country: null,
        //         crm: null,
        //         department: null,
        //         publisher: null,
        //         users: [],
        //     }
        // }
    },
    mounted() {
        this.loadTexts();
        this.$io.$on('connected', this.loadTexts);
    },
    beforeDestroy() {
        this.$io.$off('connected', this.loadTexts);
    },
}
</script>