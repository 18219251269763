<template>
    <v-sheet style="position: relative;" color="background" class="fill-height">
        <adminSidebar></adminSidebar>
        <v-container class="h-100">
            <v-row class="align-center justify-center h-100">
                <v-col cols="12" md="6" lg="4">
                    <v-card elevation="11" color="panel">
                        <v-toolbar color="toolbar" tile flat dense>
                            <v-toolbar-title>System Administration</v-toolbar-title>
                        </v-toolbar>
                        <v-divider color="white"></v-divider>
                        <v-card-text>
                            <p>Please use the navigation to enter an Administration Module</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import adminSidebar from '../../components/admin/sidebar';
export default {
    components: {
        adminSidebar
    }
}
</script>